body {
  padding: 20px;
  background: #202020; }

h1 {
  color: lightgray; }

button {
  width: 100%; }

[data-toggle="collapse"] {
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 10px;
  background-color: white;
  border: 1px solid grey;
  margin-bottom: -1px;
  outline: none;
  transition: all .5s ease-in-out; }
  [data-toggle="collapse"]:hover {
    background-color: #DCDCDC; }
  [data-toggle="collapse"][aria-expanded="true"] {
    background-color: #303030;
    color: white;
    border: 1px solid #303030; }
    [data-toggle="collapse"][aria-expanded="true"]:after {
      content: "-";
      margin-right: 2px;
      float: right; }
  [data-toggle="collapse"][aria-expanded="false"]:after {
    content: "+";
    float: right; }

.box {
  background-color: white;
  border: 1px solid grey;
  display: none;
  margin-bottom: -1px; }
  .box p {
    padding: 5px 20px; }
